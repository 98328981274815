@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap');
* {
  outline: none !important;
}

body {
  margin: 0;
}

.slick-slider {
  width: 100%;
}

@media (min-width: 992px) {
  .dropdown-menu .dropdown-toggle:after {
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }
  .dropdown-menu .dropdown-menu {
    margin-left: 0;
    margin-right: 0;
  }
  .dropdown-menu li {
    position: relative;
  }
  .nav-item .submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
  }
  .nav-item .submenu-left {
    right: 100%;
    left: auto;
  }
  .dropdown-menu > li:hover {
    background-color: #f1f1f1;
  }
  .dropdown-menu > li:hover > .submenu {
    display: block;
  }
}


.topArea{
  padding:15px 30px;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400!important;
  background-color: #145B3D;
  color:white;
  box-sizing: border-box;
}

.headerBtn{
background-color: transparent;
padding: 0;
font-size: 12px;
display: flex;
justify-content: center;
align-items: center;
}


.headerMenu *{
  font-family: 'Poppins', sans-serif;
}

.headerMenu .nav-item *{
  font-size: 18px;
  font-weight:600!important;
  /* color:grey!important; */
  margin: 0;
  
}

.headerMenu .nav-item-active *{
  font-size: 18px;
  font-weight:400px!important;
  color:#145b3d!important;
}

.loginBtn{
  /* font-size: 18px;
  font-weight:200px!important; */
  color:white!important;
  padding:0 15px!important;
}

.nav-item-active *{
  color:#145b3d!important;
  font-weight:bold;
}

.nav-link-active{
  color:#145b3d!important;
  font-weight:bold;
}

.bannerHeading1{
  color:white;
  font-family: 'Poppins', sans-serif;
  font-size: 25px;
  font-weight: bold!important;
  z-index: 2!important;
  position: relative;
}

.bannerHeading2{
  color:white;
  font-family: 'Poppins', sans-serif;
  font-size: 65px;
  font-weight: bold!important;
  z-index: 2!important;
  position: relative;
}
.bannerImage1{
  position: absolute;
  right: 100px;
  bottom: 0;
  width: 800px;
}


.btn1{
  padding:10px 50px!important;
  border-radius: 50px!important;
  background-color: #145B3D!important;
  font-family: 'Poppins', sans-serif!important;
  font-weight: bold!important;
  border: 2px solid #145B3D!important;
  color:white!important;
  width: auto!important;
}

.btn2{
  padding:10px 50px!important;
  border-radius: 50px!important;
  background-color: transparent!important;
  font-family: 'Poppins', sans-serif!important;
  font-weight: bold!important;
  border: 2px solid white!important;
  color:white!important;
  width: auto!important;
}



a{
  text-decoration: none!important;
  color:inherit!important
}
.section2{
  background-color: #145B3D;
  padding:25px;
  display:flex;
  justify-content: center;
  align-items: center;
}

.section2 h1{
  font-family: 'Poppins', sans-serif;
  font-weight: bold!important;
  margin: 0;
  font-size: 30px;
  color:white
}

.section3{
  background-color: rgba(0, 176, 116, 0.15);
  padding:50px;
  display:flex;
  min-height: 50vh;
  justify-content: center;
  align-items: center;
  position:relative
}

.section3 h1{
  font-family: 'Poppins', sans-serif;
  font-weight: bold!important;
  margin: 20px;
  text-align: center;
  font-size: 30px;
  color:#145B3D
}

.section3 h3{
  color: #535353;
text-align: center;
font-family: 'Poppins', sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.section3 p{
  color: #484848;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; 
}

.section4{
  padding:50px;
  display:flex;
  min-height: 50vh;
  justify-content: center;
  align-items: center;
  position:relative
}

.section4 h1{
  font-family: 'Poppins', sans-serif;
  font-weight: bold!important;
  margin: 20px;
  text-align: center;
  font-size: 30px;
  color:#145B3D
}

.productTitle{
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color:#484848
}

.productCount{
  font-family: 'Poppins', sans-serif;
  color: #999;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.productPrice{
  font-family: 'Poppins', sans-serif;
  color:#145B3D;
  font-size: 14px;
  font-style: "bold";
  font-weight: 400;
  line-height: 27px;
}

.growOnHover:hover{
transform: scale(1.05);
-webkit-transition: all 500ms ease-in-out;
-moz-transition: all 500ms ease-in-out;
-ms-transition: all 500ms ease-in-out;
-o-transition: all 500ms ease-in-out;
transition: all 500ms ease-in-out;
}


.growOnHover{
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  }
  

.cardTitle1{
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color:#484848
}

.footerTitle1{
  font-family: 'Poppins', sans-serif;
  color: #333;

  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.dot{
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #145B3D;
  display: flex;
}
.footerList p{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  color: #626262;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
}

.fotr-input {
  /* max-width: 448px; */
  height: 52px;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
  position: relative;
  color: rgba(153, 153, 153, 0.20)!important;
}

.fotr-input input {
  border-radius: 50px!important;
  height: 52px;
  padding-left: 20px;
  font-family: 'Poppins', sans-serif;
  border-radius: 40px;
border: 1px solid rgba(0, 0, 0, 0.05);
background: #F3F3F3;
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.17);

font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 27px; /* 225% */
}

.fotr-input .btn {
  display:flex;
  justify-content: center;
  align-self: center;
  height: 52px;
  border-radius: 50px!important;
  right: 9rem;
  z-index: 9;
  position: absolute;
  top: 0px;
  bottom: 0;
  right: 0px;
}

.fotr-input-2 {
  /* max-width: 448px; */
  height: 52px;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
  position: relative;
  color: rgba(153, 153, 153, 0.20)!important;
}

.fotr-input-2 input {
  border-radius: 50px!important;
  height: 52px;
  padding-left: 20px;
  font-family: 'Poppins', sans-serif;
  border-radius: 40px;
border: 1px solid rgba(0, 0, 0, 0.05);
background: #F3F3F3;
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.17);

font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 27px; /* 225% */
}

.fotr-input-2 .btn {
  display:flex;
  justify-content: center;
  align-self: center;
  background-color: transparent;
  color:white!important;
  font-size: 25px;
  border:none;
  right: 9rem;
  z-index: 9;
  position: absolute;
  top: 2px;
  bottom: 0;
  right: 0px;
}

.socialBtn{
  width: 40px!important;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items:center;
  color:white;
  font-size: 14px;
  background-color: #444444;
  border-radius: 50px;
}

.socialBtn:hover{
  width: 40px!important;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items:center;
  background-color:#145B3D;
  font-size: 14px;
  color: white;
  border-radius: 50px;
}


.cardTitle2{
  font-family: 'Poppins', sans-serif;
  font-weight: bold!important;
  margin:10px 0;
  text-align: left;
  font-size: 40px;
  color:#145B3D
}

.cardText{
  color: #484848;
  font-family: 'Poppins', sans-serif;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.footerTitle2{
  font-family: 'Poppins', sans-serif;
  color: #333;
  text-align: center;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.footerText{
  color: #626262;
text-align: center;
font-family: 'Poppins', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 37px; /* 231.25% */
}

.footerRow{
  justify-content: space-between;
}


.alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active{
  background-color: #29a46b!important;
}
@media (max-width: 992px) {
  .marginAdjust{
    margin-bottom: 20px;
    margin-top: 20px!important;
  }

  .topArea1{
    padding-bottom: 20px!important;
  }
  .topArea1 label{
    display: none;
  }
  .bannerHeading2 {
    font-size: 45px;
}
.btn1{
  margin-bottom: 15px;
}
.footerRow{
  text-align: center;
  justify-content: center;
  padding: 20px;
}

.mainU{
  background: #29A46B;
}
.hideOnPhone{
  display: none;
}
.opacity5{
  opacity: 0.5;
}
.card1{
  min-height: 400px;
  margin-bottom: 15px;
}

.footerCards{
  display: flex;
  text-align: center;
  justify-content: center;
}
.card8Image{
  max-width: 100%;
  height: auto;
}

.overlay {
  position: absolute; 
  bottom: 0; 
  /* background: rgb(0, 0, 0); */
  background: rgba(255, 255, 255, 0.5); /* Black see-through */
  color: #f1f1f1; 
  width: 100%;
  height: 100%;
  transition: .5s ease;
  opacity:1;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}

.subBtn{
  font-size: 10px;
  padding: 5px 10px;
  display: flex;
  justify-content:center;
  align-items: center;
}


.textCenter{
  text-align: center;
}

.blockBtn{
  display: block;
}



}
.carousel{
  padding: 0!important;
}

.topArea1 {
  padding: 5px 0px;
  font-size: 12px;
  font-family: "Poppins",sans-serif;
  font-weight: 400!important;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00463f+52,062624+100 */
background: linear-gradient(to bottom,  rgba(0,70,63,1) 52%,rgba(6,38,36,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */    color: #fff;
  box-sizing: border-box;
}
.topArea1 label{
color: #ccc;
}
.topArea1 label span{
color: #fff;
font-weight: 400;
display: block;
}
.carousel-control-next-icon, .carousel-control-prev-icon{
display: none;
}

.carousel-control-next , .carousel-control-prev{
width: 5%!important;
}
   
    .searchArea{
      width: 88%;
      height: 38px;
      margin-left: 15px;
      display: flex;
      background: #fff;
      border-radius: 8px!important;
    }
    .searchArea select{
      background-color: #d4d6d4;
      border-radius: 8px 0 0 8px;
    }
    .searchArea select, .searchArea input{
      border: 0;
      border-radius: 8px!important;
      padding-left: 20px;
    }
    .searchArea .form-select:focus, .searchArea input:focus {
  border-color:transparent;
  outline: 0;
  box-shadow:none;
}
    .searchArea button{
      width: 68px;
      background: #fce2d9;
      padding: 0 8px;
      border: 0;
      border-radius: 0px 8px 8px 0px;
    }
    .searchArea  button img{
      width: 55%;
    }
    .top-nav{
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1a5951+52,195850+100 */
background: linear-gradient(to bottom,  rgba(26,89,81,1) 52%,rgba(25,88,80,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
padding: 5px 0;
    }
    .top-nav ul{
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    .top-nav ul li{
      display: inline-block;
      padding: 0 10px 0 0;
    }
    .top-nav ul li a{
      color: #fff!important;
      text-decoration: none;
      font-size: 15px;
      font-weight: 400;
    }
    .slide-nav{
      background: #fff;
      padding: 5px 0;
      overflow-x: auto;
    }
    .slide-nav ul{
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
    }
    .slide-nav ul li{
      display: inline-block;
      padding:0;
      white-space: nowrap; 
    }

    .caretNone .dropdown-toggle::after{
      display: none!important;
    }
    .slide-nav ul li::after{
      content: "";
      color: #3b3e3c;
      padding: 0 10px;
      font-size: 14px;
    }
    .slide-nav ul li:first-child:after, .slide-nav ul li:last-child:after {
      content: "";
    }
    
    .slide-nav ul li a{
      color: #3b3e3c;
      text-decoration: none;
      font-size: 14px;
    }
    .carousel{
      /* background: url(./images/banner-bg.png) no-repeat center top; */
      background-color: #29a46b;
      background-size: cover;
    }
    .carousel-dark .carousel-indicators [data-bs-target]{
      background-color: #b8912f;
    }
    .carousel-item h5{
      color: #fff;
      font-family: 'Playfair Display', serif;
  font-weight: 300!important;
  font-size:60px;
  margin-bottom:30px;
    }
    .carousel-item h4{
      color: #fff;
      font-family: 'Playfair Display', serif;
        font-weight: 700!important;
  font-size: 55px;
    }

    @media (max-width: 1440px) {
      .loc{
        font-size: 10px!important;

      }
      .carousel-item h5{
    font-size:50px;
      }
      .carousel-item h4{
        
    font-size: 45px;
      }
  
    }


    @media (max-width: 1400px) {
      .loc{
        font-size: 12px!important;

      }
      .carousel-item h5{
        
        font-size:40px;
          }
          .carousel-item h4{
        margin-top: 20px;    
        font-size: 35px;
          }
    }


    @media (max-width: 767px) {
      .carousel-item{
        padding: 20px 0;
      }
      .carousel-item h5{
        
        font-size:30px;
          }
          .carousel-item h4{
        margin-top: 20px;    
        font-size: 25px;
          }
    }